<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <template v-if="!clientView">
            <v-col v-if="projectIssue.seen_in_minutes" cols="12" sm="6">
              <v-subheader class="pa-0">
                {{ $t('projects.general.seeing_time') }}
                <span class="font-weight-regular">
                  &nbsp;{{
                    projectIssue.seen_in_minutes
                      ? getHumanizedDuration(projectIssue.seen_in_minutes)
                      : ''
                  }}
                </span>
              </v-subheader>
            </v-col>
            <v-col v-if="projectIssue.solved_in_minutes" cols="12" sm="6">
              <v-subheader class="pa-0">
                {{ $t('projects.general.solving_time') }}
                <span class="font-weight-regular">
                  &nbsp;{{
                    projectIssue.solved_in_minutes
                      ? getHumanizedDuration(projectIssue.solved_in_minutes)
                      : ''
                  }}
                </span>
              </v-subheader>
            </v-col>
          </template>

          <FormAttachments
            :attachments="item.attachments"
            :can-delete="!$isClient()"
            @deleteAttachment="$emit('delete:attachment', $event.file)"
          />
        </v-row>

        <v-row dense>
          <v-col v-if="!clientView && !$route.params.projectId" cols="12">
            <ProjectAutocomplete
              v-model="item.project_id"
              :initial-item="item.project"
              :error-messages="errors.project_id"
              :label="formMixin_getRequiredFieldLabel($t('general.project'))"
              @update:initial-item="item.project = $event"
              @input="formMixin_clearErrors('project_id')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.title"
              :error-messages="errors.title"
              :label="formMixin_getRequiredFieldLabel($t('general.issue'))"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('title')"
            />
          </v-col>

          <v-col v-if="!clientView && !supplierView" cols="12" sm="6">
            <v-select
              v-model="item.priority"
              :items="projectIssuePriorities"
              :error-messages="errors['priority']"
              :label="$t('projects.labels.priority')"
              @change="formMixin_clearErrors('priority')"
            />
          </v-col>

          <v-col v-if="canEdit" cols="12" sm="6">
            <v-select
              v-model="item.status"
              :items="projectIssueStatuses"
              :error-messages="errors['status']"
              :label="$t('general.status')"
              @change="formMixin_clearErrors('status')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.description"
              :error-messages="errors.description"
              :label="$t('general.description')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('description')"
            />
          </v-col>

          <v-col v-if="item.type === 'external'" cols="12">
            <v-textarea
              v-model="item.response_comment"
              :error-messages="errors.response_comment"
              :label="$t('general.comment')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('response_comment')"
            />
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="attachedFiles"
              :label="$t('general.add_file')"
              :accept="filesAcceptString"
              :error-messages="filesErrorMessage"
              multiple
              @change="filesErrorMessage = ''"
            />
          </v-col>

          <template v-if="!clientView">
            <v-col cols="12" sm="6">
              <v-select
                v-if="assignees"
                v-model="item.assigned_to"
                :items="assignees"
                :error-messages="errors.assigned_to"
                :label="$t('general.assigned_to_employee')"
                item-text="personal_data.full_name"
                item-value="user_id"
                clearable
                @change="formMixin_clearErrors('assigned_to')"
              />
            </v-col>

            <!--            <v-col cols="12" sm="6">-->
            <!--              <v-checkbox-->
            <!--                v-model="item.is_in_production"-->
            <!--                :error-messages="errors.is_in_production"-->
            <!--                :label="$t('projects.labels.is_in_production')"-->
            <!--                class="form-checkbox"-->
            <!--                @change="formMixin_clearErrors('is_in_production')"-->
            <!--              />-->
            <!--            </v-col>-->

            <v-col cols="12" sm="6">
              <BaseDatepickerInput
                v-model="item.planned_implementation_at"
                :error-messages="errors['planned_implementation_at']"
                :label="$t('projects.labels.planned_implementation')"
                @input="formMixin_clearErrors('planned_implementation_at')"
              />
            </v-col>

            <v-col v-if="item.type === 'external'" cols="12" sm="6">
              <v-checkbox
                v-model="item.send_response_to_user"
                :error-messages="errors.send_response_to_user"
                :label="$t('projects.labels.inform_client')"
                class="form-checkbox"
                @change="formMixin_clearErrors('send_response_to_user')"
              />
            </v-col>

            <!--            <v-col cols="12" sm="6">-->
            <!--              <v-select-->
            <!--                v-model="item.category"-->
            <!--                :items="projectIssueCategories"-->
            <!--                :error-messages="errors['category']"-->
            <!--                :label="$t('general.category')"-->
            <!--                :hint="selectedCategory.description"-->
            <!--                persistent-hint-->
            <!--                @change="formMixin_clearErrors('category')"-->
            <!--              />-->
            <!--            </v-col>-->
          </template>
        </v-row>

        <v-row v-if="item.status === 'closed'" dense>
          <v-col cols="12">
            <v-textarea
              v-model="item.cause"
              :error-messages="errors.cause"
              :label="$t('projects.labels.cause')"
              @input="formMixin_clearErrors('cause')"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="item.lesson_learned"
              :error-messages="errors.lesson_learned"
              :label="$t('projects.labels.lesson_learned')"
              @input="formMixin_clearErrors('lesson_learned')"
            />
          </v-col>
        </v-row>

        <v-row v-if="item.status === 'closed'" dense>
          <v-col cols="12">
            <v-select
              v-model="item.time_spent"
              :items="projectIssueTimeSpentOptions"
              :error-messages="errors['time_spent']"
              :label="$t('projects.labels.time_spent')"
              @change="formMixin_clearErrors('time_spent')"
            />
          </v-col>
        </v-row>

        <v-row v-if="item.id && !supplierView" dense>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="item.is_resolved"
              :error-messages="errors.is_resolved"
              :label="$t('projects.labels.is_resolved')"
              @change="formMixin_clearErrors('is_resolved')"
            />
          </v-col>
        </v-row>

        <v-row v-if="item.id" dense>
          <v-col class="my-4" cols="12">
            <BaseComments
              :key="item.id"
              :model-id="item.id"
              class="mt-2"
              model-type="project_issues"
              @create="$emit('update:comment-count', item.comments_count + 1)"
              @delete="$emit('update:comment-count', item.comments_count - 1)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="!item.id || canEdit || $isEmployee()">
        <v-btn
          v-if="item.id && !$isClient()"
          :icon="$vuetify.breakpoint.xsOnly"
          :text="$vuetify.breakpoint.smAndUp"
          :color="$vuetify.breakpoint.xsOnly ? '' : 'primary'"
          :disabled="$store.getters.loading[`delete:api/project-issues/${item.id}`]"
          :loading="$store.getters.loading[`delete:api/project-issues/${item.id}`]"
          @click="$emit('delete', item)"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly"> delete </v-icon>
          <template v-else>
            {{ $t('general.controls.delete') }}
          </template>
        </v-btn>
        <v-btn
          v-if="item.id && $route.params.projectId && !$isClient()"
          :icon="$vuetify.breakpoint.xsOnly"
          :text="$vuetify.breakpoint.smAndUp"
          :color="$vuetify.breakpoint.xsOnly ? '' : 'primary'"
          @click.native="$emit('convert-to-user-story', item)"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly"> comment </v-icon>
          <template v-else>
            {{ $t('projects.general.convert_to_story') }}
          </template>
        </v-btn>
        <v-spacer />
        <v-btn text @click.native="close">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn :disabled="loading" :loading="loading" color="primary" text @click.native="onSubmit">
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn text @click.native="close">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FormAttachments from '../FormAttachments.vue';
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import BaseComments from '../base/BaseComments';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete';
import formMixin from '@/mixins/form-mixin';
import { formatDistanceToNow, subMinutes } from 'date-fns';

export default {
  name: 'ProjectIssueForm',

  components: {
    ProjectAutocomplete,
    BaseComments,
    BaseDatepickerInput,
    FormAttachments,
  },

  mixins: [formMixin],

  props: {
    projectIssue: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    clientView: {
      type: Boolean,
      default: false,
    },
    supplierView: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: [Number, String],
      default: 0,
    },
  },

  data() {
    return {
      item: {},
      attachedFiles: [],
      acceptedFileFormats: ['jpeg', 'bmp', 'png', 'gif'],
      filesErrorMessage: '',
    };
  },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters('projectIssues', [
      'projectIssuePriorities',
      'projectIssueStatuses',
      'projectIssueCategories',
      'projectIssueCategoriesMap',
      'projectIssueTimeSpentOptions'
    ]),

    filesAcceptString() {
      return this.acceptedFileFormats.map((format) => `.${format}`).join(', ');
    },

    formTitle() {
      if (this.item.type === 'external') {
        return this.$t('projects.modal_titles.external_issue_edition');
      }
      if (this.item.type === 'sentry') {
        return this.$t('projects.modal_titles.sentry_issue_edition');
      }
      return this.$t(`projects.modal_titles.${!this.item.id ? 'new_issue' : 'edit_issue'}`);
    },

    selectedCategory() {
      return this.projectIssueCategoriesMap[this.item.category] || {};
    },

    assignees() {
      if (this.selectedProject?.assignees) {
        return this.selectedProject.assignees;
      }
      return this.item.project?.assignees || null;
    },

    canEdit() {
      if (
        (this.clientView &&
          !this.$isCurrentUser(+this.item.received_from_id) &&
          !this.supplierView) ||
        (this.supplierView && !this.$isCurrentUser(+this.item.assigned_to))
      ) {
        return false;
      }

      return true;
    },
  },

  created() {
    this.item = JSON.parse(JSON.stringify(this.projectIssue));
  },

  methods: {
    async onSubmit() {
      const issue = {
        ...this.item,
      };
      if (this.projectId) {
        issue.project_id = this.projectId;
      }

      for (let i = 0; i < this.attachedFiles.length; i++) {
        const file = this.attachedFiles[i];
        let isFileSupported = false;

        for (let j = 0; j < this.acceptedFileFormats.length; j++) {
          if (file.type.includes(this.acceptedFileFormats[j])) {
            isFileSupported = true;
            break;
          }
        }

        if (!isFileSupported) {
          this.filesErrorMessage = this.$t('errors.unsupported_file_selected').replace(
            '{0}',
            this.filesAcceptString,
          );
          return;
        }
      }

      this.$emit('save', { issue, files: this.attachedFiles || [] });
    },

    close() {
      this.$emit('cancel', this.item);
    },

    getHumanizedDuration(minutes) {
      return formatDistanceToNow(subMinutes(new Date(), minutes));
    },
  },
};
</script>
